import { NgModule } from "@angular/core";
import { S25EmailTemplateListComponent } from "./s25.email.template.list.component";
import { S25TableModule } from "../../../s25-table/s25.table.module";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25EmailTemplateComponent } from "./s25.email.template.component";
import { S25EditableModule } from "../../../s25-editable/s25.editable.module";
import { S25EditableBooleanModule } from "../../../s25-editable/s25-editable-boolean/s25.editable.boolean.module";
import { S25InfoMessageModule } from "../../../s25-info-message/s25.info.message.module";
import { S25ToggleButtonModule } from "../../../s25-toggle-button/s25.toggle.button.module";
import { S25CheckboxModule } from "../../../s25-checkbox/s25.checkbox.module";
import { S25SimpleCollapseModule } from "../../../s25-simple-collapse/s25.simple.collapse.module";
import { BpeModule } from "../../../bpe/bpe.module";
import { S25RichTextEditorModule } from "../../../s25-rich-text-editor/s25.rich.text.editor.module";
import { S25EmailTemplatePreviewComponent } from "./s25.email.template.preview.component";
import { S25LoadingInlineModule } from "../../../s25-loading/s25.loading.inline.module";
import { S25PipesModule } from "../../../../pipes/s25.pipes.module";
import { S25MultiselectModule } from "../../../s25-multiselect/s25-multiselect.module";
import { S25ToDoTemplateComponent } from "./s25.todo.template.component";
import { S25ToDoTemplateListComponent } from "./s25.todo.template.list.component";
import { S25ToDoEmailTemplatePreviewComponent } from "./s25.todo.email.template.preview.component";

@NgModule({
    imports: [
        S25TableModule,
        CommonModule,
        FormsModule,
        S25EditableModule,
        S25EditableBooleanModule,
        S25InfoMessageModule,
        S25ToggleButtonModule,
        S25CheckboxModule,
        S25SimpleCollapseModule,
        BpeModule,
        S25RichTextEditorModule,
        S25LoadingInlineModule,
        S25PipesModule,
        S25MultiselectModule,
    ],
    declarations: [
        S25EmailTemplateListComponent,
        S25EmailTemplatePreviewComponent,
        S25EmailTemplateComponent,
        S25ToDoTemplateComponent,
        S25ToDoTemplateListComponent,
        S25ToDoEmailTemplatePreviewComponent,
    ],
    exports: [
        S25EmailTemplateListComponent,
        S25EmailTemplatePreviewComponent,
        S25EmailTemplateComponent,
        S25ToDoTemplateComponent,
        S25ToDoTemplateListComponent,
        S25ToDoEmailTemplatePreviewComponent,
    ],
    providers: [
        S25EmailTemplateListComponent,
        S25EmailTemplatePreviewComponent,
        S25EmailTemplateComponent,
        S25ToDoTemplateComponent,
        S25ToDoTemplateListComponent,
        S25ToDoEmailTemplatePreviewComponent,
    ],
})
export class S25EmailTemplateModule {}
