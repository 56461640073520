import { Component, OnInit, ViewChild } from "@angular/core";
import { Table } from "../../../s25-table/Table";
import { CacheRepository } from "../../../../decorators/cache.decorator";
import { BpeService, EmailScenario } from "../../../bpe/bpe.service";
import { Bind } from "../../../../decorators/bind.decorator";
import { GenericTableButtonComponent } from "../../../s25-table/generics/generic.table.button.component";
import { ModalService } from "../../../modal/modal.service";
import { S25TableComponent } from "../../../s25-table/s25.table.component";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { BpeUtil } from "../../../bpe/bpe.util";
import { GenericTableListComponent } from "../../../s25-table/generics/generic.table.list.component";

@TypeManagerDecorator("s25-ng-email-scenario-list")
@Component({
    selector: "s25-ng-email-scenario-list",
    template: `
        <h2 class="c-margin-bottom--single">Scenario Settings</h2>
        @if (isInit) {
            <div class="top">
                <div class="header">
                    <button class="aw-button aw-button--primary" (click)="onCreateClick()">Add Scenario</button>
                    <label>
                        Preview reference:
                        <input type="text" [(ngModel)]="eventReference" class="c-input" placeholder="YYYY-ABCDEF" />
                    </label>
                </div>
                <s25-ng-table
                    [dataSource]="tableData"
                    [hasRefresh]="true"
                    [hasFilter]="true"
                    [columnSortable]="true"
                    [pivotThreshold]="800"
                ></s25-ng-table>
            </div>
        }
    `,
    styles: `
        .top {
            max-width: 1200px;
        }

        .header {
            display: flex;
            justify-content: space-between;
        }

        .header > button {
            margin-bottom: 1em;
        }
    `,
})
export class S25EmailScenarioListComponent implements OnInit {
    isInit = false;
    tableData: Table.DataSource;
    scenarios = new Map<number, EmailScenario>();
    eventReference: string;

    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;

    ngOnInit() {
        this.tableData = this.getTableConfig();
        this.isInit = true;
    }

    getTableConfig(): Table.DataSource {
        return {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: [
                {
                    id: "name",
                    header: "Scenario",
                },
                {
                    id: "active",
                    header: "Active",
                    width: 70,
                },
                {
                    id: "templates",
                    header: "Templates",
                    content: { component: GenericTableListComponent },
                },
                {
                    id: "todoTemplates",
                    header: "To Do Templates",
                    content: { component: GenericTableListComponent },
                },
                GenericTableButtonComponent.Column("Preview", this.onPreviewClick, "outline"),
                GenericTableButtonComponent.Column("Copy", this.onCopyClick, "outline"),
                GenericTableButtonComponent.Column("Edit", this.onEditClick, "outline"),
                GenericTableButtonComponent.Column("Delete", this.onDeleteClick, "danger--outline"),
            ],
        };
    }

    @Bind
    async getRows(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) CacheRepository.invalidateByService("BpeService");
        const scenarios = await BpeService.getFullScenarios();
        for (let scenario of scenarios) this.scenarios.set(scenario.itemId, scenario);

        return {
            rows: scenarios.map(this.mapToRow),
        };
    }

    mapToRow(item: EmailScenario): Table.Row {
        const templateNames: string[] = item.templates?.map((template) => template.itemName as string) ?? [];
        const templates: Table.Cell[] = templateNames.map((name) => ({ text: name }));

        let todoTemplateNames: string[] =
            item.todoTemplates?.map((todoTemplate) => todoTemplate.itemName as string) ?? [];

        if (!item.createToDo) todoTemplateNames = [];

        const todoTemplates: Table.Cell[] = todoTemplateNames.map((todoName) => ({ text: todoName }));

        return {
            id: item.itemId,
            name: item.itemName,
            cells: {
                name: {
                    text: item.itemName,
                },
                active: {
                    text: item.isActive ? "Yes" : "No",
                },
                templates: { inputs: { items: templates }, textValue: templateNames.join(" ") },
                // createToDo: { text: item.createToDo ? "Yes" : "No" },
                todoTemplates: { inputs: { items: todoTemplates }, textValue: todoTemplateNames.join(" ") },
            },
        };
    }

    onCreateClick() {
        ModalService.modal("edit-email-scenario", {
            scenario: {
                itemName: "New Scenario",
                mode: "form",
                onAction: "create",
                isActive: 1,
                isScheduled: 0,
                code: "",
                onEventStateChangeAction: 1,
                onEventFormAction: 1,
                onExpressAction: 1,
            },
            title: `Create Scenario`,
            onSave: this.tableComponent.refresh,
            reference: this.eventReference,
        });
    }

    @Bind
    onPreviewClick(row: Table.Row) {
        if (!this.eventReference) return alert("Please provide an event reference for the preview.");

        const scenario = this.scenarios.get(row.id as number);
        const code = BpeUtil.scenarioToPreviewCode(scenario);
        ModalService.modal("email-scenario-preview", {
            name: scenario.itemName,
            code: `{${code}}`, // Wrap to avoid
            reference: this.eventReference,
            templates:
                scenario?.templates?.map((template) => ({
                    name: template.itemName,
                    code: `{${template.mode === "form" ? BpeUtil.templateToCode(template) : template.code}}`,
                })) || [],
        });
    }

    @Bind
    onCopyClick(row: Table.Row) {
        const scenario = S25Util.deepCopy(this.scenarios.get(row.id as number));
        delete scenario.itemId;
        scenario.itemName += " copy";
        ModalService.modal("edit-email-scenario", {
            scenario,
            title: `Copy Scenario: ${row.name}`,
            onSave: this.tableComponent.refresh,
            reference: this.eventReference,
        });
    }

    @Bind
    onEditClick(row: Table.Row) {
        ModalService.modal("edit-email-scenario", {
            scenario: this.scenarios.get(row.id as number),
            title: `Edit Scenario: ${row.name}`,
            onSave: this.tableComponent.refresh,
            reference: this.eventReference,
        });
    }

    @Bind
    async onDeleteClick(row: Table.Row) {
        let dialogData = ModalService.dialogType(
            "Yes No",
            {
                message: "Are you sure you want to delete this email scenario?",
                title: "Confirm Deletion",
            },
            "No",
        );
        await ModalService.modal("dialog", dialogData);
        if (dialogData.answer !== 1) return; // User answered no

        await BpeService.deleteScenario(row.id as number).catch(this.error);
        return this.tableComponent.refresh();
    }

    error(error: any) {
        S25Util.showError(error);
    }
}
