import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { BpeService, EmailTemplate, ToDoTemplate } from "../../../bpe/bpe.service";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { BpeUtil } from "../../../bpe/bpe.util";
import { ModalService } from "../../../modal/modal.service";
import { S25LoadingApi } from "../../../s25-loading/loading.api";
import { TelemetryService } from "../../../../services/telemetry.service";
import { S25ItemI } from "../../../../pojo/S25ItemI";
import { MultiselectModelI } from "../../../s25-multiselect/s25.multiselect.component";
import { MasterDefinitionTagsService } from "../../../../services/master.definitions/master.definition.tags.service";
import { MasterDefTag } from "../../../../pojo/MasterDefTag";
import { Bind } from "../../../../decorators/bind.decorator";
import { SearchCriteriaService } from "../../../../services/search/search-criteria/search.criteria.service";
import { Report } from "../../../../pojo/Report";
import Reports = Report.Reports;
import ObjectType = Report.ObjectType;

@TypeManagerDecorator("s25-ng-email-template")
@Component({
    selector: "s25-ng-email-template",
    template: `
        @if (isInit) {
            <div>
                @if (!template.isToDoTemplate) {
                    <div class="templateRow">
                        <label for="item-name" class="ngBold">Template Name: </label>
                        <input
                            [(ngModel)]="template.itemName"
                            id="item-name"
                            name="item-name"
                            class="c-input"
                            type="text"
                        />
                    </div>
                }
                @if (!template.isToDoTemplate) {
                    <div class="templateRow">
                        <label for="is-manual" class="ngBold">Manual Template: </label>
                        <s25-toggle-button
                            [(modelValue)]="template.isManual"
                            id="is-manual"
                            name="is-manual"
                        ></s25-toggle-button>
                    </div>
                    @if (template.isManual) {
                        <s25-ng-multiselect-search-criteria
                            [type]="'systemTags'"
                            [selectedItems]="selectedTags"
                            [modelBean]="selectedTagsBean"
                            [popoverOnBody]="true"
                            [popoverPlacement]="'bottom-left bottom-right right-bottom'"
                            class="templateRow"
                        >
                        </s25-ng-multiselect-search-criteria>
                    }
                }
                @if (template.mode === "form") {
                    <div>
                        @if (!template.isToDoTemplate) {
                            <div>
                                <label class="ngBold">Template Instructions</label>
                                <s25-ng-info-message [open]="true">
                                    <span>You may use the recipient shortcuts below to target certain users:</span>
                                    <ul>
                                        <li>'object owner' - send to the object owner's work email address</li>
                                        <li>
                                            'service providers' - send to the work email address of the contacts that
                                            have approval workflow in this event
                                        </li>
                                        <li>
                                            'assigners' - send to the work email address of the contacts that have
                                            assignment workflow in this event
                                        </li>
                                        <li>
                                            Event role name - use the work email address associated with the contact in
                                            this role (eg, 'scheduler', 'requestor', 'emergency contact')
                                        </li>
                                        <li>'attendees' - send to the work email address of all registrants</li>
                                    </ul>
                                </s25-ng-info-message>
                                <div class="templateRow">
                                    <label for="show-form" class="ngBold">Custom "From" Address: </label>
                                    <s25-toggle-button
                                        [(modelValue)]="showFrom"
                                        id="show-form"
                                        name="show-form"
                                    ></s25-toggle-button>
                                </div>
                                <div class="templateRow">
                                    <label for="to" class="ngBold">To </label>
                                    <input type="text" [(ngModel)]="template.to" id="to" name="to" class="c-input" />
                                </div>
                                @if (showFrom) {
                                    <div class="templateRow">
                                        <label for="from" class="ngBold">From</label>
                                        <input
                                            type="text"
                                            [(ngModel)]="template.from"
                                            id="from"
                                            name="from"
                                            class="c-input"
                                        />
                                    </div>
                                }
                                <div class="templateRow">
                                    <label for="cc" class="ngBold">CC </label>
                                    <input type="text" [(ngModel)]="template.cc" id="cc" name="cc" class="c-input" />
                                </div>
                                <div class="templateRow">
                                    <label for="bcc" class="ngBold">BCC </label>
                                    <input type="text" [(ngModel)]="template.bcc" id="bcc" name="bcc" class="c-input" />
                                </div>
                                <div class="templateRow">
                                    <label class="ngBold">Reports</label>
                                    <s25-ng-checkbox
                                        [(modelValue)]="reports.confirmation"
                                        (modelValueChange)="onReportsChange()"
                                        id="reports-confirmation"
                                        name="reports-confirmation"
                                    >
                                        Confirmation
                                    </s25-ng-checkbox>
                                    <s25-ng-checkbox
                                        [(modelValue)]="reports.invoice"
                                        (modelValueChange)="onReportsChange()"
                                        id="reports-invoice"
                                        name="reports-invoice"
                                    >
                                        Invoice
                                    </s25-ng-checkbox>
                                </div>
                                <div class="templateRow">
                                    <label></label>
                                    <div>
                                        <s25-ng-multiselect-search-criteria
                                            [selectedItems]="selectedReports"
                                            [modelBean]="modelBean"
                                            honorMatching="true"
                                            [type]="'eventTypeReports'"
                                            [popoverOnBody]="true"
                                            [popoverPlacement]="'bottom-left bottom-right right-bottom'"
                                        ></s25-ng-multiselect-search-criteria>
                                    </div>
                                </div>
                                <div class="templateRow">
                                    <label class="ngBold">iCal File</label>
                                    <s25-toggle-button
                                        [(modelValue)]="template.iCalFile"
                                        id="iCalFile"
                                        name="iCalFile"
                                    ></s25-toggle-button>
                                </div>
                                <div class="templateRow">
                                    <label for="subject" class="ngBold">Subject </label>
                                    <input
                                        type="text"
                                        [(ngModel)]="template.subject"
                                        id="subject"
                                        name="subject"
                                        class="c-input"
                                    />
                                </div>
                                <label for="body" class="ngBold c-margin-top--half">Body </label>
                                <s25-ng-rich-text-editor
                                    [(modelValue)]="template.body"
                                    [defaultHeight]="500"
                                    id="body"
                                    name="body"
                                ></s25-ng-rich-text-editor>
                            </div>
                        }
                        @if (template.isToDoTemplate) {
                            <div>
                                <s25-ng-todo-template
                                    [template]="$any(this.template)"
                                    (modelValueChange)="onTodDoTempChange($event)"
                                ></s25-ng-todo-template>
                            </div>
                        }
                    </div>
                }
                @if (template.mode === "code") {
                    <div>
                        <label class="ngBold">Code:</label>
                        <textarea [(ngModel)]="template.code" rows="20" cols="120" class="cn-form__control"></textarea>
                    </div>
                }
                <div
                    class="simple-collapse--wrapper c-margin-top--single templateVarsCollapse c-objectDetails--borderedSection"
                >
                    <div class="c-sectionHead">
                        <h2>Template Variables</h2>
                    </div>
                    <s25-simple-collapse [defaultCollapsed]="true" [titleText]="'Template Variables'">
                        <div class="c-evddWrapper">
                            <bpe-vars [isToDoTemplate]="template.isToDoTemplate"></bpe-vars>
                        </div>
                    </s25-simple-collapse>
                </div>
                <label class="ngBold d-block c-margin-top--single">Advanced </label>
                <button class="aw-button aw-button--outline d-block" (click)="toggleView()">
                    {{ template.mode === "form" ? "Code" : "Form" }} View
                </button>
                <label for="preview-reference" class="ngBold d-block c-margin-top--single">Preview by Reference </label>
                <div class="templateRow">
                    <input
                        [(ngModel)]="eventReference"
                        id="preview-reference"
                        name="preview-reference"
                        class="c-input"
                        placeholder="YYYY-ABCDEF"
                    />
                    <button class="aw-button aw-button--primary" (click)="showPreview()">Preview</button>
                </div>
                <s25-loading-inline [model]="{}"></s25-loading-inline>
                <div class="buttons c-margin-top--single">
                    <button class="aw-button aw-button--primary" (click)="onSave()">Save</button>
                    <button class="aw-button aw-button--outline" (click)="onCancel()">Cancel</button>
                </div>
            </div>
        }
    `,
    styles: `
        .templateRow {
            display: flex;
            gap: 1em;
            align-items: center;
            margin: 0.5em 0;
        }

        .templateRow > label:first-child {
            min-width: 10em;
            margin: 0;
        }

        .templateRow > input {
            min-width: 15em;
        }

        .reports {
            display: flex;
            justify-content: space-between;
            width: 15em;
        }

        .buttons {
            display: flex;
            gap: 0.5em;
        }

        ::ng-deep s25-ng-email-template s25-ng-rich-text-editor .tox-tinymce {
            width: 100% !important;
        }

        s25-ng-info-message {
            margin-left: 0.5em;
        }

        ::ng-deep .s25-multiselect-popup-container {
            max-width: 50vw;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EmailTemplateComponent implements OnInit {
    @Input() template: EmailTemplate | ToDoTemplate;
    @Input() eventReference: string = "";

    @Output() saved = new EventEmitter<{ id: number }>();
    @Output() cancelled = new EventEmitter<void>();

    isInit = false;
    showFrom = false;
    reports = {
        confirmation: false,
        invoice: false,
    };
    modelBean: MultiselectModelI = { showResult: true, showMatching: false, extractItems: this.filterReports };
    selectedReports: S25ItemI[] = [];
    reportsList: any = [];

    selectedTags: S25ItemI[] = [];
    selectedTagsBean: MultiselectModelI = { showResult: true, showMatching: false };

    constructor(
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    async ngOnInit() {
        !this.template.isToDoTemplate ? (this.template.isToDoTemplate = 0) : ""; // this is email template so set isToDoTemplate to 0
        this.template = S25Util.deepCopy(this.template); // Copy to avoid changing original

        this.template = this.template.isToDoTemplate
            ? (this.template as EmailTemplate)
            : (this.template as ToDoTemplate);

        if (this.template.isToDoTemplate === 0) {
            this.template = this.template as EmailTemplate;
            this.showFrom = !!this.template.from;
            this.initTagsMultiselect();
            this.template.reports = this.template.reports.toString(); // in case just a report id
            this.reports.confirmation = this.template.reports.includes("confirmation");
            this.reports.invoice = this.template.reports.includes("invoice");
            if (this.template.reports !== "") {
                const reportsList = await SearchCriteriaService.getEventTypeReports();
                this.reportsList = this.filterReports(reportsList);
                this.getSelectedReport();
            }
        }

        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    onReportsChange() {
        (this.template as EmailTemplate).reports = Object.entries(this.reports)
            .filter(([_, val]) => val)
            .map(([key]) => key)
            .join(",");
    }

    initTagsMultiselect() {
        this.selectedTags =
            this.template.tags?.map((tag) => {
                return { itemId: tag.tagId, itemName: tag.tagName };
            }) || [];

        S25Util.extend(this.selectedTagsBean, {
            preFetchItems: false,
            title: "System Tags",
            selectedItems: this.selectedTags,
        });
    }

    onICalChange() {
        // console.log({ reports: this.template });
    }

    async onSave() {
        if (this.template && this.template.isToDoTemplate === 1) {
            this.template = this.template as ToDoTemplate;
            if (
                this.template.assignBy.toString().indexOf("{{$pro.vars.roleContactId") === -1 &&
                !this.template.multiSelectBeanAssignBy
            ) {
                return alert("Please enter a valid contact or Event Role Variable for the Assigned By field.");
            } else if (this.template.assignBy.toString().indexOf("{{$pro.vars.roleContactId") > -1) {
            } else if (
                this.template.multiSelectBeanAssignBy &&
                this.template.multiSelectBeanAssignBy.selectedItems?.[0]
            ) {
                this.template.assignBy = this.template.multiSelectBeanAssignBy.selectedItems?.[0].itemId;
            }

            if (
                this.template.assignTo.toString().indexOf("{{$pro.vars.roleContactId") === -1 &&
                !this.template.multiSelectBeanAssignTo
            ) {
                return alert("Please enter a valid contact or Event Role Variable for the Assigned To field.");
            } else if (this.template.assignTo.toString().indexOf("{{$pro.vars.roleContactId") > -1) {
            } else if (
                this.template.multiSelectBeanAssignTo &&
                this.template.multiSelectBeanAssignTo.selectedItems?.[0]
            ) {
                this.template.assignTo = this.template.multiSelectBeanAssignTo.selectedItems?.[0].itemId;
            }

            if (this.template.taskName.indexOf("{{$pro.vars.") === -1 && this.template.taskName.length > 40) {
                return alert("Task name must be 40 characters or less.");
            }

            if (!Number.isInteger(this.template.dueDate)) {
                return alert("Please enter a valid number for Due Date field.");
            }

            if (!this.template.itemName) return alert("A template name is required");
            if (!this.template.taskName) return alert("A template task name is required");
            if (!this.template.assignBy) return alert("A template assign by is required");
            if (!this.template.assignTo) return alert("A template assign to is required");
            S25LoadingApi.init(this.elementRef.nativeElement);
            TelemetryService.sendWithSub("EventSaveEmail", "Template", "CreateTodo");
            const resp = await BpeService.putTemplateByTemplate(this.template).catch(this.error);
            S25LoadingApi.destroy(this.elementRef.nativeElement);
            this.saved.emit({ id: resp.root.itemId });
        } else {
            this.template = this.template as EmailTemplate;

            if (!this.template.itemName) return alert("A template name is required");
            if (!this.template.to && this.template.mode === "form") return alert('A "to" address is required');
            if (!this.showFrom) this.template.from = ""; // If we hide the "from" field don't save its data
            this.template.isManual = this.template.isManual ? 1 : 0;
            this.template.iCalFile = this.template.iCalFile ? 1 : 0;
            S25LoadingApi.init(this.elementRef.nativeElement);

            // add report selectedItems to template.reports
            if (this.modelBean.selectedItems && this.modelBean.selectedItems.length > 0) {
                this.onReportsChange(); // call this again to get new list  ensure no duplicated
                let selectedItems = S25Util.toItemIds(this.modelBean.selectedItems).toString();
                this.template.reports !== ""
                    ? (this.template.reports = this.template.reports + "," + selectedItems)
                    : (this.template.reports = selectedItems);
            }

            // removed report removedItems to template.reports
            if (this.modelBean.removedItems && this.modelBean.removedItems.length > 0) {
                let tempList: any = [];
                let tempReportList = this.template.reports.split(",");
                tempReportList.forEach((e: any) => {
                    if (e) {
                        let n = Number(e);
                        let find: any;
                        if (!isNaN(n)) {
                            find = this.modelBean.removedItems.find((r: any) => {
                                return r && r.itemId === n;
                            });
                        }
                        if (!find) {
                            tempList.push(e);
                        }
                    }
                });
                this.template.reports = tempList.join(",");
            }

            const resp = await BpeService.putTemplateByTemplate(this.template).catch(this.error);
            if (
                resp?.root?.itemId &&
                (this.selectedTagsBean.addedItems?.length || this.selectedTagsBean.removedItems?.length)
            )
                await MasterDefinitionTagsService.editTagsOnObject(
                    MasterDefTag.types.email_template,
                    0,
                    resp?.root?.itemId,
                    this.selectedTagsBean.addedItems.map((item) => item.itemId as number),
                    this.selectedTagsBean.removedItems.map((item) => item.itemId as number),
                );
            S25LoadingApi.destroy(this.elementRef.nativeElement);
            this.saved.emit({ id: resp.root.itemId });
        }
    }

    onCancel() {
        this.cancelled.emit();
    }

    async toggleView() {
        if (this.template.mode === "form") {
            // Need to convert form data into code
            this.template.isToDoTemplate
                ? (this.template.code = BpeUtil.todoTemplateToCode(this.template))
                : (this.template.code = BpeUtil.templateToCode(this.template as EmailTemplate));
        } else {
            // Warn user that code changes may be lost
            let dialogData = ModalService.dialogType(
                "Yes No",
                {
                    message:
                        "Changes made to code may be lost when switching to Form View. Are you sure that you want to switch?",
                    title: "Switch to Form View",
                },
                "No",
            );
            await ModalService.modal("dialog", dialogData);
            if (dialogData.answer !== 1) return; // User answered no
        }
        this.template.mode = this.template.mode === "form" ? "code" : "form";
        this.changeDetector.detectChanges();
    }

    showPreview() {
        if (!this.template.isToDoTemplate) {
            const code =
                this.template.mode === "form"
                    ? BpeUtil.templateToCode(this.template as EmailTemplate)
                    : this.template.code;
            ModalService.modal("email-template-preview", {
                name: this.template.itemName,
                code,
                reference: this.eventReference,
                isToDoTemplate: this.template.isToDoTemplate,
            });
        } else {
            const code = this.template.mode === "form" ? BpeUtil.todoTemplateToCode(this.template) : this.template.code;
            ModalService.modal("todo-email-template-preview", {
                name: this.template.itemName,
                code,
                reference: this.eventReference,
                isToDoTemplate: this.template.isToDoTemplate,
            });
        }
    }

    onTemplateChange(e: any) {
        if (this.template.isToDoTemplate) {
            this.template = {
                mode: "form",
                itemName: "New To Do Template",
                comment: "",
                assignBy: "",
                assignTo: "",
                dueDate: null,
                taskName: "",
                code: "",
                itemId: null,
                isToDoTemplate: 1,
            } as ToDoTemplate;
        } else {
            this.template = {
                mode: "form",
                cc: "",
                reports: "",
                itemName: "New Email Template",
                bcc: "",
                code: "",
                subject: "",
                isManual: 0,
                iCalFile: 0,
                to: "",
                body: "",
                isToDoTemplate: 0,
            } as EmailTemplate;
        }
    }

    onTodDoTempChange(e: any) {
        this.template = e;
    }

    getSelectedReport() {
        (this.template as EmailTemplate).reports.split(",").map((e: any) => {
            let n = Number(e);
            if (!isNaN(n)) {
                let find = this.reportsList.find((r: any) => {
                    return r && r.report_id === n;
                });
                if (find) this.selectedReports.push({ itemId: n, itemName: find?.report_name });
            }
        });
    }

    error(error: any) {
        S25Util.showError(error);
    }

    @Bind
    filterReports(reports: Report.Object[]): ReturnType<MultiselectModelI["extractItems"]> {
        const disallowedReports: Set<number> = new Set([
            Reports.OrganizationShell.id,
            Reports.ReservationShell.id,
            Reports.EventShell.id,
        ]);
        const allowedObjectTypes: Set<number> = new Set([
            ObjectType.Event,
            ObjectType.EventDocument,
            ObjectType.OrganizationDocument,
        ]);
        return reports.filter((report) => {
            return (
                !disallowedReports.has(Number(report.report_id)) && allowedObjectTypes.has(Number(report.object_type))
            );
        });
    }
}
