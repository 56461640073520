import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from "@angular/core";
import { BpeService, EmailTemplate } from "../../../bpe/bpe.service";
import { EventService } from "../../../../services/event.service";
import { S25LoadingApi } from "../../../s25-loading/loading.api";
import { Bind } from "../../../../decorators/bind.decorator";
import { BpeUtil } from "../../../bpe/bpe.util";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { ExecutionEnvironment } from "../../../../services/execution.enviroment";

@TypeManagerDecorator("s25-ng-email-template-preview")
@Component({
    selector: "s25-ng-email-template-preview",
    template: `
        <s25-loading-inline [model]="{}"></s25-loading-inline>
        @if (status === "failed") {
            <div>
                <p>Failed to load event, please make sure your event reference is correct</p>
            </div>
        }
        @if (status === "success") {
            <div>
                <div>
                    <div>To: {{ preview.to }}</div>
                    <div>From: {{ preview.from }}</div>
                    <div>CC: {{ preview.cc }}</div>
                    <div>BCC: {{ preview.bcc }}</div>
                    <div>Reports: {{ preview.reports }}</div>
                    <div>Subject: {{ preview.subject }}</div>
                    <div>Body:</div>
                    <div [innerHtml]="preview.body | safeHTML"></div>
                </div>
            </div>
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EmailTemplatePreviewComponent implements OnInit {
    @Input() name: EmailTemplate["itemName"];
    @Input() scenarioName?: string;
    @Input() code: EmailTemplate["code"];
    @Input() reference: string;

    status: "loading" | "failed" | "success" = "loading";
    preview: EmailTemplate;

    constructor(
        private elementRef: ElementRef,
        private changeDetector: ChangeDetectorRef,
    ) {}

    async ngOnInit() {
        this.isLoading(true);
        const id = await EventService.getEventIdByLocator(this.reference).catch(this.failed);
        if (!id) return this.failed();
        const eventDataPromise = BpeService.getEventData(id, true);
        const additionalDataPromise = BpeService.getAdditionalEventData({ postEventDataPromise: eventDataPromise });
        const [event] = await Promise.all([eventDataPromise, additionalDataPromise]);
        const vars = await BpeUtil.getVariables(event, null, event);
        vars.scenario = this.scenarioName;
        vars.template = this.name;
        const code = BpeUtil.interpolateString(vars, this.code);
        this.isLoading(false);

        try {
            this.preview = ExecutionEnvironment.run(code, {
                vars,
                eventData: event,
                util: BpeUtil.util,
            });
            this.preview.subject = S25Util.unescapeXml(this.preview.subject);
            this.status = "success";
        } catch (error: any) {
            S25Util.showError(error);
            this.failed();
        }

        this.changeDetector.detectChanges();
    }

    isLoading(loading: boolean) {
        if (loading) S25LoadingApi.init(this.elementRef.nativeElement);
        else S25LoadingApi.destroy(this.elementRef.nativeElement);
    }

    @Bind
    failed() {
        this.status = "failed";
        this.isLoading(false);
        this.changeDetector.detectChanges();
    }
}
