import { Component, OnInit, ViewChild } from "@angular/core";
import { Table } from "../../../s25-table/Table";
import { Bind } from "../../../../decorators/bind.decorator";
import { BpeService, ToDoTemplate } from "../../../bpe/bpe.service";
import { CacheRepository } from "../../../../decorators/cache.decorator";
import { GenericTableButtonComponent } from "../../../s25-table/generics/generic.table.button.component";
import { ModalService } from "../../../modal/modal.service";
import { S25TableComponent } from "../../../s25-table/s25.table.component";
import { BpeUtil } from "../../../bpe/bpe.util";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { ContactService } from "../../../../services/contact.service";

@TypeManagerDecorator("s25-ng-todo-template-list")
@Component({
    selector: "s25-ng-todo-template-list",
    template: `
        <h2 class="c-margin-bottom--single">To Do Template Settings</h2>
        @if (isInit) {
            <div class="top">
                <div class="header">
                    <button class="aw-button aw-button--primary" (click)="onCreateClick()">Add Template</button>
                    <label>
                        Preview reference:
                        <input type="text" [(ngModel)]="eventReference" class="c-input" placeholder="YYYY-ABCDEF" />
                    </label>
                </div>
                <s25-ng-table
                    [dataSource]="tableData"
                    [hasRefresh]="true"
                    [hasFilter]="true"
                    [columnSortable]="true"
                    [pivotThreshold]="800"
                ></s25-ng-table>
            </div>
        }
    `,
    styles: `
        .top {
            max-width: 1200px;
        }

        .header {
            display: flex;
            justify-content: space-between;
        }

        .header > button {
            margin-bottom: 1em;
        }
    `,
})
export class S25ToDoTemplateListComponent implements OnInit {
    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;

    isInit: boolean = false;
    tableData: Table.DataSource;
    templates = new Map<number, ToDoTemplate>();
    eventReference: string = "";

    ngOnInit() {
        this.tableData = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: [
                {
                    id: "name",
                    header: "Name",
                },
                {
                    id: "taskName",
                    header: "Task Name",
                },
                {
                    id: "assignTo",
                    header: "Assign To",
                },
                {
                    id: "assignBy",
                    header: "Assign By",
                },

                GenericTableButtonComponent.Column("Preview", this.onPreviewClick, "outline"),
                GenericTableButtonComponent.Column("Copy", this.onCopyClick, "outline"),
                GenericTableButtonComponent.Column("Edit", this.onEditClick, "outline"),
                GenericTableButtonComponent.Column("Delete", this.onDeleteClick, "danger--outline"),
            ],
        };
        this.isInit = true;
    }

    @Bind
    async getRows(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) CacheRepository.invalidateByService("BpeService");
        const templates = await BpeService.getFullTodoTemplates();
        for (let template of templates) {
            if (template.assignTo && Number.isInteger(template.assignTo)) {
                template.multiSelectBeanAssignTo = {};
                template.multiSelectBeanAssignTo.selectedItems = [{ itemId: template.assignTo }];
                template.assignTo = await ContactService.getContactName(parseInt(template.assignTo));
            }

            if (template.assignBy && Number.isInteger(template.assignBy)) {
                template.multiSelectBeanAssignBy = {};
                template.multiSelectBeanAssignBy.selectedItems = [{ itemId: template.assignBy }];
                template.assignBy = await ContactService.getContactName(parseInt(template.assignBy));
            }
            this.templates.set(template.itemId, template);
        }

        return {
            rows: templates.map(this.mapToRow),
        };
    }

    onCreateClick() {
        const template: Omit<ToDoTemplate, "itemId"> = {
            mode: "form",
            itemName: "New To Do Template",
            comment: "",
            assignBy: "",
            assignTo: "",
            dueDate: 0,
            taskName: "",
            isToDoTemplate: 1,
            code: "",
        };
        ModalService.modal("edit-email-template", {
            template,
            title: `Create New To Do Template`,
            onSave: this.tableComponent.refresh,
            reference: this.eventReference,
        });
    }

    @Bind
    onPreviewClick(row: Table.Row) {
        if (!this.eventReference) return alert("Please provide an event reference for the preview.");

        const template: any = this.templates.get(row.id as number);
        const code = template.mode === "form" ? BpeUtil.todoTemplateToCode(template) : template.code;
        ModalService.modal("email-template-preview", {
            name: template.itemName,
            code,
            reference: this.eventReference,
            isToDoTemplate: template.isToDoTemplate,
        });
    }

    @Bind
    onCopyClick(row: Table.Row) {
        const template: Partial<ToDoTemplate> = S25Util.deepCopy(this.templates.get(row.id as number));
        delete template.itemId; // Omit id to create as new
        template.itemName += " copy";

        ModalService.modal("edit-email-template", {
            template,
            title: `Copy Template: ${row.name}`,
            onSave: this.tableComponent.refresh,
            reference: this.eventReference,
        });
    }

    @Bind
    onEditClick(row: Table.Row) {
        ModalService.modal("edit-email-template", {
            template: this.templates.get(row.id as number),
            title: `Edit Template: ${row.name}`,
            onSave: this.tableComponent.refresh,
            reference: this.eventReference,
        });
    }

    @Bind
    async onDeleteClick(row: Table.Row) {
        let dialogData = ModalService.dialogType(
            "Yes No",
            {
                message: "Are you sure you want to delete this email template?",
                title: "Confirm Deletion",
            },
            "No",
        );
        await ModalService.modal("dialog", dialogData);
        if (dialogData.answer !== 1) return; // User answered no

        await BpeService.deleteTemplate(row.id as number, "todo").catch(this.error);
        this.tableComponent.refresh();
    }

    mapToRow(item: any): Table.Row {
        return {
            id: item.itemId,
            name: String(item.itemName),
            cells: {
                name: {
                    text: item.itemName,
                },
                taskName: {
                    text: item.taskName,
                },
                assignTo: {
                    text: item.assignTo,
                },
                assignBy: {
                    text: item.assignBy,
                },
            },
        };
    }

    error(error: any) {
        S25Util.showError(error);
    }
}
