import { NgModule } from "@angular/core";
import { S25EmailScenarioListComponent } from "./s25.email.scenario.list.component";
import { CommonModule } from "@angular/common";
import { S25TableModule } from "../../../s25-table/s25.table.module";
import { S25EmailScenarioComponent } from "./s25.email.scenario.component";
import { FormsModule } from "@angular/forms";
import { S25ToggleButtonModule } from "../../../s25-toggle-button/s25.toggle.button.module";
import { S25InfoMessageModule } from "../../../s25-info-message/s25.info.message.module";
import { S25CheckboxModule } from "../../../s25-checkbox/s25.checkbox.module";
import { S25RadioModule } from "../../../s25-radio/s25.radio.module";
import { S25MultiselectModule } from "../../../s25-multiselect/s25-multiselect.module";
import { S25SimpleCollapseModule } from "../../../s25-simple-collapse/s25.simple.collapse.module";
import { S25DropdownPaginatedModule } from "../../../s25-dropdown/s25.dropdown.paginated.module";
import { S25CustomAttributeModule } from "../../../s25-custom-attribute/s25.custom.attribute.module";
import { BpeModule } from "../../../bpe/bpe.module";
import { S25LoadingInlineModule } from "../../../s25-loading/s25.loading.inline.module";
import { S25IconModule } from "../../../s25-icon/s25.icon.module";

@NgModule({
    imports: [
        CommonModule,
        S25TableModule,
        FormsModule,
        S25ToggleButtonModule,
        S25InfoMessageModule,
        S25CheckboxModule,
        S25RadioModule,
        S25MultiselectModule,
        S25SimpleCollapseModule,
        S25DropdownPaginatedModule,
        S25CustomAttributeModule,
        BpeModule,
        S25LoadingInlineModule,
        S25IconModule,
    ],
    exports: [S25EmailScenarioListComponent, S25EmailScenarioComponent],
    declarations: [S25EmailScenarioListComponent, S25EmailScenarioComponent],
    providers: [S25EmailScenarioListComponent, S25EmailScenarioComponent],
})
export class S25EmailScenarioModule {}
